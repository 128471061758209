<template>
  <div class="d-flex">
    <div class="pa-0">
      <v-list-item-subtitle id="metric__subtitle" class="text-wrap caption">{{
        $t(`metricNames.${metric.name}`)
      }}</v-list-item-subtitle>
      <v-list-item-title
        class="headline mb-1 font-weight-black"
        id="metric__value"
        >{{ metric.metric }}</v-list-item-title
      >
      <!-- <div class="d-flex caption text-wrap">
        <v-icon
          v-if="metric.difference"
          :color="metric.difference >= 0 ? 'increase': 'decrease'"
          size="17"
        >{{metric.difference >= 0 ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
        <div
          :v-if="metric.difference"
          :class="[metric.difference >= 0 ? 'increase--text' : 'decrease--text', 'mr-1']"
        >{{Math.abs(metric.difference).toFixed(1)}}{{metric.diffSign && metric.diffSign}}</div>
        <v-list-item-subtitle class="caption">Pre:{{metric.pre}}{{metric.preSign && metric.preSign}}</v-list-item-subtitle>
      </div> -->
    </div>
    <v-divider v-if="hasDivider" vertical class="mx-3"></v-divider>
  </div>
</template>

<script>
export default {
  name: "MetricItem",
  props: ["metric", "hasDivider"],
  watch: {
    metric() {
      console.log("metric change");
    },
  },
};
</script>

<style scoped></style>
